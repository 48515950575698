@import '../../../assets/scss/media-query.scss';

.checkout {
    width: 100%;
    position: sticky;
    top: 3%;
    .calendar {
        border-radius: 16px;
        border: 1px solid #EAEAEA;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.03);
        padding: 1rem;
        margin-bottom: 3rem;
        .title {
            margin-bottom: 0.75rem;
            p {
                color: #000;
                font-family: Poppins;
                font-size: 21px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @include lt-md{
                    font-size: 16px;
                }
            }
        }

        .box {
            p {
                color: #7F7F7F;
                margin-bottom: 0.5rem;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @include lt-md{
                    font-size: 12px;
                }
            }
            
        }
    }
    .shop{
        border-radius: 16px;
        border: 1px solid #EAEAEA;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.03);
        padding: 1rem;
        margin-bottom: 1.5rem ;
        .title {
            padding-bottom: 0.75rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.30);
            p {
                color: #000;
                font-family: Poppins;
                font-size: 21px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @include lt-md{
                    font-size: 16px;
                }
            }
        }

        .box {
            padding-top: 0.75rem;
            p {
                color: #7F7F7F;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @include lt-md{
                    font-size: 13px;
                }
            }
           
        }
        .config{
            min-height: 100px;
            width: 100%;
            padding-bottom: 0.75rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.30);
            .addons{
                display: flex;
                gap: 5px;
                align-items: flex-start;
                margin-top: 0.5rem;
                .dflex{
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    .configs{
                        
                    }

                }
                p{
                    color: #7F7F7F;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    @include lt-md{
                        font-size: 12px;
                    }
                }
            }
        }
        .sub_total{
            display: flex;
            justify-content: space-between;
            margin: 1rem 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.30);
            padding-bottom: 0.75rem;
            p{
                color: #7F7F7F;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @include lt-md{
                    font-size: 12px;
                }
            }
        }
        .total{
            display: flex;
            justify-content: space-between;
            margin: 1rem 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.30);
            padding-bottom: 0.75rem;
            p{
                color: #7F7F7F;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @include lt-md{
                    font-size: 12px;
                }
            }
        }
        .btn{
            width: 100%;
            margin: 1rem 0;
            button{
                border-radius: 10px;
                background: linear-gradient(90deg, #1CA1E2 0%, #60C1D0 100%);
                width: 100%;
                padding:0.5rem 1rem;
                display: flex;
                justify-content: center;
                outline: none;
                border: none;
                color: #FFF;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                cursor: pointer;
                @include lt-md{
                    font-size: 14px;
                }
            }
        }
    }
}

.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  
    .modal-content {
      background-color: white;
      padding: 20px;
      border-radius: 5px;
  
      p {
        margin-bottom: 20px;
      }
  
      .button-container {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        button {
          margin-left: 10px;
          cursor: pointer;
          border-radius: 10px;
          width: 100%;
          padding:0.5rem 1rem;
          display: flex;
          justify-content: center;
          outline: none;
          border: none;
          color: #FFF;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          @include lt-md{
              font-size: 14px;
          }
        }
        & :first-child{
            background: linear-gradient(90deg, #e21c1c 0%, #d06060 100%);
        }
        & :last-child{
            color: #000;
        }
    }
    }
  }
  

  .btnEmpty{
    width: 100%;
    margin: 1rem 0;
    button{
        border-radius: 10px;
        background: linear-gradient(90deg, #e21c1c 0%, #d06060 100%);
        width: 100%;
        padding:0.5rem 1rem;
        display: flex;
        justify-content: center;
        outline: none;
        border: none;
        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        @include lt-md{
            font-size: 14px;
        }
    }
}