@import '../../assets/scss/media-query.scss';

.main_card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    gap: 15px;
    .splited_box{
        width: 94%;
        padding: 0.15rem 0.75rem;
        border-radius: 8px;
        border: 0.5px solid #727272;
        background: #FFF;
        display: flex;
        height: 40px;
            justify-content: space-between;
            align-items: center;
            @include lt-md{
                height: 35px;
                width: 80%;
            }
        .desc_items{
            display: flex;
            gap: 10px;
            align-items: center;
            img{
                width: 40px;
                height: auto;
                @include lt-md{
                    width: 35px;
                }
            }
            p{
                color: #7F7F7F;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .pen_box{
            img{
                
            }
        }
    }
    
    .delete{
        width: 5%;
        height: 40px;
        border-radius: 6px;
        background: linear-gradient(180deg, #1CA1E2 0%, #60C1D0 100%);
        display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.15rem 0;
            @include lt-md{
                height: 35px;
                width: 10%;
            }
    }
}