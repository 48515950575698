@import '../../assets/scss/media-query.scss';

.box_seller{
    width: 100%;
    .flex{
        display: flex;
        justify-content: center;
        .main_box_container{
            .logo{
                margin: 3rem 0; 
                width: 100%;
            }
            width: 80%;
            margin-bottom: 3rem;
            @include lt-md{
                margin-bottom: 1rem;
                width: 95%;
            }
            .box{
                display: flex;
                justify-content: space-between;
                @include lt-md{
                    flex-direction: column;
                }
                .w_48{
                    width: 49%;
                    margin-bottom: 3rem;
                    @include lt-md{
                        margin-bottom: 1rem;

                        width: 100%;
                    }
                }
        
            }
            .w_100{
                width: 100%;
                margin-bottom: 3rem;
                @include lt-md{
                    margin-bottom: 1rem;

                    width: 100%;
                }
            }
        }
    }
}