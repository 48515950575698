@import '../../../assets/scss/media-query.scss';

.headlines {
    margin: 2rem 0;
    @include lt-md{
        margin: 1rem 0;
    }
    .title {
        margin: 3rem 0 2rem 0;

        @include lt-md{
            margin: 1.5rem 0 1.5rem 0;
        }
        p {
            color: #20213C;
            font-family: Poppins;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            @include lt-md{
                font-size: 20px;
            }
        }
    }

    .description {
        p {
            color: #7F7F7F;

            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @include lt-md{
                font-size: 12px;
            }
        }
    }
}