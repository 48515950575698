.boxCard{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 0.5rem ;
    .cup{
    width: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
        img{
            height: 34px;
            width: 38px;
        }
    }
    .card_main{
        width: 87%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        .img_text{
            display: flex;
            align-items: center;
            gap: 10px;
            .img{
                img{
                    height: 32px;
                    width: 32px;
                }
            }
            p{
                color: #000;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
        p{
            color: #000;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
    
    .card_main_empty{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem 0.5rem 0.25rem;
        border-radius: 8px;
        .img_text{
            display: flex;
            align-items: center;
            gap: 10px;
            .img{
                img{
                    height: 32px;
                    width: 32px;
                }
            }
            p{
                color: #000;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
        p{
            color: #000;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
}

.background_blue{
    background-color: #1DA1E2;

}

.background_green{
    background-color: #60C1D0;

}
.background_white{
    background-color: #FFF;
    border: 0.5px solid #727272;
}