@import '../../assets/scss/media-query.scss';

.tagline_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include lt-md{
        align-items: center;

    }
    .tab{
        .title{
            color: #20213C;
            font-family: Poppins;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0.5rem 0 0.25rem 0 ;
            @include lt-md{
                font-size: 18px;
            }
        }
        .sub{
            color: #7F7F7F;
    
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0.5rem 0 1.25rem 0;
            @include lt-md{
                font-size: 12px;
            }
        }
        img {
            &.transactionIcon {
              width: 26px; 
              height: 26px;
              margin-left: 2px;
              padding-top: 5px;
            }
          }

    }
}