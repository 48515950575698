.footer{
    width: 100%;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    .box{
        width: 90%;
        .container_footer{
            display: flex;
            justify-content: flex-end;
            .footer_card{
                p{
                    color: #000;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-align: center;
                }
                img{
                    margin-top: 0.5rem;
                    height: 38px;
                    width: 100%;
                }
    
            }
        }
    }

}