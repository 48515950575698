@import '../../assets/scss/media-query.scss';

.error{
    font-size: 12px;
    color: #ff1000;

}
.modal_data {
    width: 100%;
    display: flex;
    justify-content: center;

    .container {
        width: 95%;

        .modal_title {
            width: 100%;

            p {
                color: #000;
                font-family: Poppins;
                font-size: 21px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @include lt-md{
                    font-size: 17px;
                }

            }
            
        }
        .card_mobile{
            margin: 0.5rem 0;
            p{
                font-size: 14px;
                color: #727272;
                font-weight: 500;
            }
        }
        .boxCard{
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            margin-top: 1.5rem ;
            border: 1px solid #fff;
            border: 0.5px solid #727272;
            border-radius: 8px;
            .card_main_empty{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0.5rem 1rem 0.5rem 0.25rem;
                border-radius: 8px;
                
                .img_text{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .img{
                        img{
                            height: 32px;
                            width: 32px;
                        }
                    }
                    p{
                        color: #000;
                        font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
                p{
                    color: #000;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
        .modal_form {
            width: 100%;

            .form_text {

                width: 100%;

                .box {
                    margin: 0.5rem 0;
                    width: 100%;
                    position: relative;
                    label {
                        color: #1C1C1C;
                        margin-bottom: 0.25rem;
                        font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    .border_select{
                        position: absolute;
                        top: 100%;
                        border-radius: 8px;
                        border: 0.5px solid #727272;
                        background: white;
                        width: 100%;
                        z-index: 9999;
                    }
                    .input_text {
                        width: 100%;
                        border-radius: 8px;
                        border: none;
                        border: 0.5px solid #727272;
                        margin-top: 0.5rem;
                        padding: 0.5rem !important;
                        background: #FFF;
                        outline: none;
                        display: flex;
                        gap: 5px;
                        box-sizing: border-box;
                        .input_text_div{
                            font-size: 10px;
                            background: #72727236;
                            border-radius: 4px;
                            padding: 0.25rem 10px;
                        }
                    }

                    .input_file {
                        width: 100%;
                        border-radius: 8px;
                        border: none;
                        border: 0.5px solid #727272;
                        margin-top: 0.5rem;
                        padding: 0.5rem !important;
                        background: #FFF;
                        outline: none;
                        height: 80px;
                        position: relative;
                        box-sizing: border-box;
                        overflow-y: scroll;
                        .many_files{
                            display: flex;
                            gap: 10px;
                            padding: 0.5rem;
                            position: absolute;
                            flex-wrap: wrap;
                            .div_many{
                                width: 120px;
                                height: 80px;
                                @include lt-md{
                                    width: 31%;
                                }
                                .img_many{
                                    height: 100%;
                                    width: 100%;
                                    border-radius: 8px;
                                }
                            }
                        }

                        .cloud {
                            
                            height: 100%;
                            width: 100%;
                            .group {
                                height: 100%;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                p{
                                    color: #7F7F7F;

                                    font-family: Poppins;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }
                        }
                        .imgFile{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            object-fit: cover;
                            /* aspect-ratio: 10/5; */
                            border-radius: 7px;
                        }
                    }
                    .input_file_filled {
                        width: 100%;
                        border-radius: 8px;
                        border: none;
                        border: 0.5px solid #727272;
                        margin-top: 0.5rem;
                        background: #FFF;
                        outline: none;
                        height: 100px;
                        position: relative;
                        box-sizing: border-box;
                        overflow-y: scroll;
                        .many_files{
                            display: flex;
                            gap: 10px;
                            position: absolute;
                            flex-wrap: wrap;
                            padding: 0.5rem;
                            .div_many{
                                width: 120px;
                                height: 80px;
                                position: relative;
                                @include lt-md{
                                    width: 31%;
                                }
                                .delete_icon{
                                    position: absolute;
                                    right: 5%;
                                    top: 10%;
                                    height: 28px;
                                    width: 28px;
                                    border-radius: 4px;
                                    background: #a5a0a09b;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    cursor: pointer;
                                    z-index: 9999;
                                    i{
                                        color: #1CA1E2;
                                    }
                                }
                                .img_many{
                                    height: 100%;
                                    width: 100%;
                                    border-radius: 8px;
                                }
                            }
                            .plus{
                                height: 80px;
                                width: 80px;
                                background-color: white;
                                border: 1px solid #1ca0e2bf;
                                border-radius: 4px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #1ca0e2bf;
                            }
                        }
                        .cloud {
                            
                            height: 100%;
                            width: 100%;
                            .group {
                                height: 100%;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                p{
                                    color: #7F7F7F;

                                    font-family: Poppins;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }
                        }
                        .cloud_op {
                            height: 100%;
                            width: 100%;
                            position: relative;
                            z-index: 0;
                            .group {
                                height: 100%;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                p{
                                    color: #000;

                                    font-family: Poppins;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }
                        }
                        .imgFile{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            object-fit: cover;
                            /* aspect-ratio: 10/5; */
                            border-radius: 7px;
                            z-index: -1;
                        }
                    }
                    .hidden {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        cursor: pointer;
                    }
                }
                .flex{
                    display: flex;
                    gap: 15px;
                    .box_w50{
                        margin: 0.5rem 0;
                        width: 49%;

                    label {
                        color: #1C1C1C;
                        margin-bottom: 0.25rem;
                        font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    .input_text {
                        width: 100%;
                        border-radius: 8px;
                        border: none;
                        border: 0.5px solid #727272;
                        margin-top: 0.5rem;
                        padding: 0.5rem !important;
                        background: #FFF;
                        outline: none;
                    }
                    }

                }
                a {
                    margin-top: 1.5rem;
                    width: 100%;
                    background: #1CA1E2;
                    height: 40px;
                    color: white;
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    outline: none;
                    border-radius: 8px;
                }
                button {
                    margin-top: 1.5rem;
                    width: 100%;
                    background: #1CA1E2;
                    height: 40px;
                    color: white;
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    outline: none;
                    border-radius: 8px;
                }
            }
        }
    }
}

.modal_data {
    width: 100%;
    display: flex;
    justify-content: center;
   
    .container {
        width: 95%;

        .modal_flex {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
            align-items: center;
            @include lt-md{
                flex-direction: column;
                align-items:flex-start;
            }
            p {
                color: #000;
                font-family: Poppins;
                font-size: 21px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @include lt-md{
                    font-size:17px
                }
            }
            .text_btn{
                width: 300px;
                border-radius: 8px;
                background: #1CA1E2;
                color: white;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                height: 42px;
                align-items: center;
                gap: 2;
                gap: 15px;
                justify-content: center;
                border: none;
                outline: none;
                @include lt-md{
                    width: 100%;
                    height: 32px;
                }
            }
            }
            .prod_select {
                width: 100%;
                margin: 2rem 0;
                p{
                    color: #1C1C1C;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 0.5rem!important;
                }
            }
        }

}

.modal_data_delete{
    width: 100%;
    display: flex;
    justify-content: center;
   
    .container {
        width: 95%;

        .modal_flex {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
            align-items: center;
            @include lt-md{
                flex-direction: column;
                align-items:flex-start;
            }
            p {
                color: #000;
                font-family: Poppins;
                font-size: 21px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @include lt-md{
                    font-size:17px
                }
            }
          
            }
            .btn_f{
                display: flex;
                justify-content: space-between;
                gap: 15px;
                .btn{
                    cursor: pointer;
                    width: 48%;
                    border-radius: 8px;
                    background: #1CA1E2;
                    color: white;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    display: flex;
                    height: 42px;
                    align-items: center;
                    gap: 2;
                    gap: 15px;
                    justify-content: center;
                    border: none;
                    outline: none;
                    @include lt-md{
                        width: 48%;
                        height: 32px;
                    }
                }
                .btn_no{
                    cursor: pointer;
                    width: 48%;
                    border-radius: 8px;
                    background: #fff;
                    color: #1CA1E2;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    display: flex;
                    height: 42px;
                    align-items: center;
                    gap: 2;
                    gap: 15px;
                    justify-content: center;
                    outline: none;
                    border: 1px solid #1CA1E2;

                    @include lt-md{
                        width: 48%;
                        height: 32px;
                    }
                }
                }
        }

}

.add-info-modal-box {
    button {
        margin-top: 1.5rem;
        width: 100%;
        background: #1CA1E2;
        height: 40px;
        color: white;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        border-radius: 8px;
    }
}