@import '../../../assets/scss/media-query.scss';
.order_box {
    margin: 2.5rem 0;

    .box_array {
        width: 100%;
        margin: 2rem 0;
        .array {
            border-radius: 8px;
            border: 0.5px solid #727272;

            background: #FFF;

            .title {
                color: rgba(0, 0, 0, 0.58);
                display: flex;
                justify-content: space-between;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding: 0.65rem 1rem;
                align-items: center;
                .title_one {
                    width: 20%;
                }

                .title_two {
                    width: 15%;
                    text-align: center;
                }

                .title_three {
                    width: 20%;
                    text-align: center;
                }

                .title_four {
                    width: 15%;
                    text-align: center;
                }

                .title_five {
                    width: 20%;
                    text-align: center;
                    @include lt-md{
                        width: 60%;
                        text-align: left;
                    }
                }
                .title_six {
                    width: 10%;
                    text-align: center;
                     @include lt-md{
                        width: 40%;
                        text-align: left;
                    }
                }
            }

            .desc {
                color: #000;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0.65rem 1rem;
                border-top: 1px solid #727272;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                .img_flex{
                    display: flex;
                    gap: 10px;
                    width: 20%;
                    align-items: center;
                    img{
                        width: 40px;
                        height:auto
                        
                    }
                    .desc_one {
                        width: 100%;
                    }
                }
                .desc_two {
                    width: 15%;
                    text-align: center;
                }

                .desc_three {
                    width: 20%;
                    text-align: center;
                }

                .desc_four {
                    width: 15%;
                    text-align: center;
                }

                .desc_five {
                    width: 20%;
                    text-align: center;
                    @include lt-md{
                        width: 60%;
                        text-align: left;
                    }
                }
                .desc_six {
                    width:10%;
                    text-align: center;
                    text-decoration: underline;
                    color: #1DA1E2;
                    cursor: pointer;
                    @include lt-md{
                        width: 40%;
                        text-align: left;
                    }
                }
            }
        }
        .paginator{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin-top: 1.5rem;
            button{
                padding: 0.5rem;
                background-color: white;
                color: #60C1D0;
                outline: none;
                border: 1px solid #60C1D0;
                cursor: pointer;
            }
        }
    }
}