@import '../../assets/scss/media-query.scss';

.btn{
    width: 100%;
    margin: 1rem 0;
    button{
        border-radius: 10px;
        background: linear-gradient(90deg, #1CA1E2 0%, #60C1D0 100%);
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: center;
        outline: none;
        border: none;
        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        @include lt-md{
            font-size: 14px;
            padding: 0.5rem 1rem;
        }
        &:hover{
            background:linear-gradient(90deg, #1CA1E2 0%, #60C1D0 50%) ;
        }
        &:disabled{
             background:linear-gradient(90deg, #8bb8cf 0%, #96c2c9 50%) ;
        }
    }
    .error{
        font-size: 14px;
        text-align: center;
        color: #ff1000;
        margin-top: 1rem;
    }
}
.form_input{
    margin-top: 1rem;
    label{
        margin-bottom: 0.25rem;
        font-size: 0.93rem;
    }
    input{
        width: 100%;
        outline: none;
        padding:  0.75rem;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #e6e6e6;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
    }
}