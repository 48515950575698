@import '../../../assets/scss/media-query.scss';

.box {
    width: 100%;
    margin-bottom: 2rem;
    
   
    .container {
        height: 100%;
        position: relative;
        width: 100%;

        
        .header_img {
            width: 100%;
            position: relative;
            border-radius: 15px;
            border: 1px solid black;
            img {
                width: 100%;
                height: 350px;
                border-radius: 15px;
                object-fit: cover;
            }

            .date {
                position: absolute;
                display: flex;
                gap: 15px;
                padding: 0.5rem 0.75rem;
                top: 5%;
                left: 5%;
                border-radius: 26.5px;
                border: 1px solid #FFF;
                background: rgba(255, 255, 255, 0.62);
                backdrop-filter: blur(5.5px);

                p {
                    color: #000;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    @include lt-md{
                        font-size: 14px;
                    }
                }

                .calendar {
                    height: 24px;
                    height: 24px;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }
            }

            .arrows {
                display: flex;
                gap: 15px;
                padding: 0.35rem 0.75rem;
                border-radius: 21px;
                border: 1px solid #FFF;
                background: rgba(255, 255, 255, 0.62);
                backdrop-filter: blur(5.5px);
                position: absolute;
                bottom: 8%;
                right: 5%;

                .arrow {
                    height: 28px;
                    width: 28px;
                    cursor: pointer;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .pricing {
            display: flex;
            justify-content: space-between;
            margin: 1.5rem 0 0.75rem 0;
            @include lt-md{
                margin: 0.5rem 0 0.75rem 0;
                
            }
            p:nth-child(0) {
                color: #20213C;
                font-family: Poppins;
                font-size: 21px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                @include lt-md{
                    font-size: 17px;
                }
            }

            p:nth-child(1) {
                color: #000;

                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @include lt-md{
                    font-size: 16px;
                }
            }
        }

        .description {
            margin: 1.5rem 0 0.75rem 0;
            @include lt-md{
                margin: 0.5rem 0 0.75rem 0;
                
            }
            p {
                color: #20213C;

                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                @include lt-md{
                    font-size: 14px;
                }
            }
        }

        .detailed {
            margin: 0.75rem 0;
            p {
                color: #7F7F7F;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                word-break: break-word;
            }
        }

        .quantity {
            margin: 0.75rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {

                color: #2C2C2C;
                margin: 0px !important;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                @include lt-md{
                    font-size: 14px;
                }
            }

            .buttons {
                display: flex;
                gap: 8px;
                align-items: center;
                .light {
                    border-radius: 8px;
                    border: 1px solid #7F7F7F;
                    background-color: #FFF;
                    width: 82px;
                    height: 38px;
                    outline: none;
                    display: flex;
                    justify-content:center ;
                    align-items: center;
                    @include lt-md{
                        width: 60px;
    height: 35px;
                    }
                }

                p {
                    color: #000;

                    font-family: Poppins;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    @include lt-md{
                        font-size: 16px;
                    }
                }

                .blue {
                    border-radius: 9px;
                    background: linear-gradient(90deg, #1CA1E2 0%, #60C1D0 100%);
                    width: 82px;
                    border: none;

                    height: 38px;
                    outline: none;
                    display: flex;
                    justify-content:center ;
                    align-items: center;
                    @include lt-md{
                        width: 60px;
    height: 35px;
                    }
                }
            }
        }
    }
}