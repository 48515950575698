@import '../../assets/scss/media-query.scss';

.container_box{
    display: flex;
    justify-content: center;
    .w_80{
        width: 70%;
        @include lt-md{
            width: 95%;
        }
        .card_prod{
            display: flex;
            gap: 35px;
            @include lt-md{
                flex-direction: column;
            }
            .w_60{
                width: 59%;
                position: relative;
                @include lt-md{
                    width: 100%;
                }
                
            }
            .opacity_drop{
                cursor: not-allowed;
                opacity: 0.4;
            }
            .w_40{
                
                width: 40%;
                @include lt-md{
                    width: 100%;
                }
            }
        }
    }
}