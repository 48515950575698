@import '../../../assets/scss/media-query.scss';

.container{
    width: 100vw;
    height: 45vh;
    .img_header{
        height: 100%;
        width: 100%;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover; 
            // @include lt-md{
            //     object-fit: cover;
            // }
        }
    }

}