
@import '../../assets/scss/media-query.scss';

.tagline_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include lt-md{
        flex-direction: column;
        align-items: flex-start;
    }
    .description{
        .title{
            color: #20213C;
            font-family: Poppins;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0.5rem 0 0.25rem 0 ;
            @include lt-md{
                font-size: 18px;
            }
        }
        .sub{
            color: #7F7F7F;
    
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0.5rem 0 1.25rem 0;
            @include lt-md{
                font-size: 12px;
            }
        }
        .text_btn{
            width: 30%;
            border-radius: 8px;
            background: #1CA1E2;
            color: white;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @include lt-md{
                width: 100%;
            }
        }
    }
    .descriptions{
        @include lt-md{
            width: 100%;
        }
        .text_btn{
            width: 300px;
            border-radius: 8px;
            background: #1CA1E2;
            color: white;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            height: 42px;
            align-items: center;
            gap: 2;
            gap: 15px;
            justify-content: center;
            border: none;
            outline: none;
            @include lt-md{
                width: 100%;
                height: 32px;
                margin-top: 1rem;
        }
        }
        select{
            width: 300px;
            padding: 0.5rem;
            color: #000;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-radius: 8px;
            height: 42px;
            @include lt-md{
                width: 100%;
                padding: 0.25rem 0.5rem;
                height: 32px;
            }
        }
    }
}