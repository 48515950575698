@import '../../assets/scss/media-query.scss';

.box_seller{
    width: 100%;
    .flex{
        display: flex;
        justify-content: center;
        .main_box_container{
            .logo{
                margin: 3rem 0; 
                width: 100%;
            }
            width: 80%;
            margin-bottom: 3rem;
            @include lt-md{
                margin-bottom: 1rem;
                width: 95%;
            }
            .box{
                height: 50vh;
                width: 100%;
                .auth_box{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    flex-direction: column;
                    gap:1.5rem;
                    .img_succ{

                        color:#5cb85c;
                        text-align: center;
                        
                    }
                    p{
                        color:#5cb85c;
                        font-size: 24px;
                        text-align: center;
                        font-weight: 500;
                    }
                    .img_fialed{
                        text-align: center;
                        color: #ff4747;
                      
                    }
                    p:nth-child(1){
                        font-size: 24px;
                        text-align: center;
                        font-weight: 500;
                        color: #ff4747;
                    }
                }
        
            }
           
        }
    }
}