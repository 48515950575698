.list{
    width: 100%;
    border-bottom: 1px solid rgba(114, 114, 114, 0.14); 
    padding: 0.35rem;
    .flex{
        display: flex;
        align-items: center;
        gap: 20px;
        .box{
            border-radius: 5px;
            border: 0.5px solid #727272;
            width: 21px;
            height: 21px;
            background: #FFF;
            .filled{
                width: 100%;
                height: 100%;
                background-color: #20213C;
                border-radius: 4px;
            }
        }
        .flex_inside{
            display: flex;
            align-items: center;
            gap: 10px;
            p{
                color: #7F7F7F;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            img{
                width: 28px;
                height: 28px;
            }
        }
    }
    
}