.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  
    h1 {
      font-size: 3rem;
      margin-bottom: 1rem;
      color: #333;
      font-family: 'Arial', sans-serif;
    }
  
    span {
      font-size: 2rem;
      color: #333;
    }
  }
  